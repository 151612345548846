/* You can add global styles to this file, and also import other style files */
html,
body {
  margin: 0;
  padding: 0;
background-color: #fff;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #5b5b5b!important;
  --mdc-slider-focus-handle-color: #616161!important;
  --mdc-slider-hover-handle-color: #616161!important;
  --mdc-slider-active-track-color: #cacaca!important;
  --mdc-slider-inactive-track-color: #cacaca!important;
  --mdc-slider-with-tick-marks-active-container-color: #cacaca!important;
  --mdc-slider-with-tick-marks-inactive-container-color: #cacaca!important;
  --mat-mdc-slider-ripple-color: #616161!important;
  --mat-mdc-slider-hover-ripple-color: rgba(63, 81, 181, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(63, 81, 181, 0.2);
}